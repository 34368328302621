<template lang="pug">
b-container.py-3
  .mb-3
    b-link.text-reset(:to='{ name: "Catalog" }')
      arrow-left-icon.mr-2.icon-md.mb-1
      span Regresar

  b-row.justify-content-center
    b-col(md='6', v-if='product.index')
      b-row
        b-col(cols='5')
          b-img(:src='`${server}/images/${product.image}.png`', fluid, style='max-height: 250px')
        b-col(cols='7')
          h4.font-weight-bold.mb-3 {{ product.name }}
          p SKU: {{ product.sku }}
          p Marca: {{ product.brand }}
          p(v-for='line in getProductSpecs(product.description)', :key='line') - {{ line }}
          .tlx(v-if='product.tlx === 1')
            b-img(src='~@/assets/images/icons/tick.png')
            p Disponible en tienda Tlaxcala
          b-button(:variant='isInCart ? "outline-danger" : "primary"', @click='isInCart ? removeFromCart(product) : addProduct(product)') {{ isInCart ? "Quitar de cotización" : "Agregar a cotización" }}
    b-col.text-center.py-5(md='6', v-else)
      h4 Producto no encontrado
      p.text-secondary Verifica que tienes la liga de producto correcta
</template>

<script>
import { mapState } from 'vuex'
import cartHelper from '@/helpers/cartHelper'
import { EventBus } from '@/helpers/bus'

export default {
  mixins: [cartHelper],
  data() {
    return {
      server: process.env.VUE_APP_URL_SERVER,
      product: {},
    }
  },
  methods: {
    getProductSpecs(properties) {
      try {
        return properties.split('/')
      } catch (e) {
        console.log(e.message)
        return ''
      }
    },
    addProduct(product) {
      this.addToCart(product)
      EventBus.$emit('showCart')
    },
  },
  computed: {
    ...mapState({
      products: (state) => state.products,
      cart: (state) => state.cart,
    }),
    isInCart() {
      if (!this.product.index) return false
      return this.cart.findIndex((prod) => prod.index == this.product.index) >= 0
    },
  },
  mounted() {
    this.product = this.products.find((product) => product.index == this.$route.params.product_id)

    if (!this.product.index) {
      this.$router.push({ name: 'Catalog' })
    }
  },
}
</script>

<style lang="scss" scoped>
.tlx {
  display: flex;
  flex-direction: row;

  p {
    font-weight: bold;
    font-size: 18px;
    color: #292d78;
  }

  img{
    margin-top: 7px;
    margin-right: 10px;
    width: 15px;
    height: 15px;
  }
}
</style>